export const setupScrollAnimations = () => {
    const animatedSections = document.querySelectorAll('.scroll-animate');
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1,
      rootMargin: '0px 0px -50px 0px'
    });
  
    animatedSections.forEach(section => {
      observer.observe(section);
    });
  };