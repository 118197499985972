import React from 'react';
import './useCases.css';
import PurpleImage from '../assests/purple-img.svg';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DataFreedomImage from '../assests/data-freedom-image.svg'
import UseCase1 from '../assests/Agent SRE/Use cases/Hybrid Issue Resolution.jpg'
import UseCase2 from '../assests/Agent SRE/Use cases/Proactive Resource Scaling.jpg'
import UseCase3 from '../assests/Agent SRE/Use cases/Automated Compliance Management.jpg'
import UseCase4 from '../assests/Agent SRE/Use cases/Root Cause Automation.jpg'


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function UseCases() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='central-container'>
      <div className='use-case-row'>
        <div className='usecase-col1'>
          <p className='use-case-header'>Use Cases</p>
          <p className='use-case-heaser-text'>Efficient DevOps Operations with Agentic AI Automation</p>
          <p className='use-case-description'>Automates tasks, optimizes workflows, and enhances system scalability and reliability</p>
          <div className='use-case-purple-back'>
            <img src={PurpleImage} alt="purpleimage" />
          </div>
        </div>
        <div className='usecase-col2'>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ borderBottom: 'none' }}>
              <Tabs 
                value={value} 
                onChange={handleChange} 
                aria-label="basic tabs example"
                variant="fullWidth"
                TabIndicatorProps={{
                  sx: {
                    height: 2,
                    background: 'transparent',
                  }
                }}
              >
                {['Hybrid issue resolution', 'Proactive resource scaling', 'Compliance management', 'Root cause automation'].map((label, index) => (
                  <Tab 
                  key={index}
                  label={label}
                  {...a11yProps(index)} 
                    sx={{
                      textTransform: 'capitalize',
                      fontFamily: 'Poppins',
                      fontSize: '18px',
                      '&.Mui-selected': {
                        color: '#000000',
                        fontWeight:600,
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          left: '50%',
                          bottom: 0,
                          height: 2,
                          width: '70%',
                          transform: 'translateX(-50%)',
                          backgroundColor: '#000000',
                        },
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <CustomTabPanel value={value} index={0}>
              <div className='data-freedom-content'>
                <p className='data-freedom-content-header'>Hybrid Issue Resolution</p>
                <p className='data-freedom-content-lower'>Agent SRE monitors hybrid infrastructure, detects issues in real-time, and provides automated resolutions, reducing downtime and improving consistency. </p>
                <img className="usecaseimage"  src={UseCase1} alt="data=freedom" />
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div className='data-freedom-content'>
                <p className='data-freedom-content-header'>Proactive Resource Scaling </p>
                <p className='data-freedom-content-lower'>Agent SRE analyzes system performance, recommending proactive resource scaling to ensure cost-efficiency and optimal usage without manual intervention.</p>
                <img className="usecaseimage" src={UseCase2} alt="data=freedom" />
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <div className='data-freedom-content'>
                <p className='data-freedom-content-header'>Compliance Management</p>
                <p className='data-freedom-content-lower'>Automatically manages configurations, ensuring continuous compliance with security and privacy standards, and applies fixes to prevent security breaches. </p>
                <img className="usecaseimage" src={UseCase3} alt="data=freedom" />
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <div className='data-freedom-content'>
                <p className='data-freedom-content-header'>Root Cause Automation </p>
                <p className='data-freedom-content-lower'>Agent SRE identifies root causes through real-time monitoring, offering detailed issue summarization, reducing the time spent on manual diagnostics. </p>
                <img className="usecaseimage" src={UseCase4} alt="data=freedom" />
              </div>
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default UseCases;