import React, { useState } from 'react';
import './FaqSection.css';
import Minus from '../assests/minus-circle.svg';
import Add from '../assests/add-circle.svg';

const Features = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "How does Agent SRE handle issues? ",
            answer: "Agent SRE automatically detects and summarizes issues in real-time, providing detailed insights and actionable solutions to resolve incidents with minimal downtime. "
        },
        {
            question: "Can Agent SRE scale resources automatically?",
            answer: "Yes, Agent SRE uses AI to monitor resource usage and automatically scale infrastructure based on real-time demand, optimizing performance and costs. "
        },
        {
            question: "Does Agent SRE integrate with existing tools?",
            answer: "Agent SRE seamlessly integrates with popular DevOps tools, allowing you to automate workflows without disrupting your existing infrastructure or toolchain."
        },
        {
            question: "What makes Agent SRE proactive?",
            answer: "Agent SRE proactively monitors systems, detecting issues early and providing recommendations or automated fixes to prevent larger problems from arising. "
        },
        {
            question: "How does Agent SRE manage configurations?",
            answer: "Agent SRE automates configuration management, ensuring Infrastructure as Code (IaC) is applied consistently and compliant with security and regulatory standards across environments. "
        },
        {
            question: "Can Agent SRE optimize hybrid infrastructures?",
            answer: "Yes, Agent SRE monitors hybrid cloud and on-premise environments, ensuring optimal performance, resource management, and issue detection across diverse infrastructure setups. "
        },
    ];

    return (
        <div className='central-container'>
            {faqs.map((faq, index) => (
                <div className='FaqContainer' key={index}>
                    <div className='FaqHeader' onClick={() => toggleFAQ(index)}>
                        <p className='FaqHeader-desc'>{faq.question}</p>
                        <img src={openIndex === index ? Minus : Add} alt="Toggle icon" />
                    </div>
                    {openIndex === index && (
                        <div className='blur-box'>
                            <p className='blur-box-desc'>{faq.answer}</p>
                        </div>
                    )}
                    <hr style={{ color: '#d4d4d4', backgroundColor: '#000000', height: '0px' }} />
                </div>
            ))}
        </div>
    );
};

export default Features;
