import './App.css';
import React, { useRef, useEffect } from 'react';
import headerLogo from './assests/sreheaderlogo.svg'
import Button from '@mui/material/Button';
import StairContainer from './components/stairContainer';
import UseCases from './components/useCases';
import Features from './components/Features';
import FaqSection from './components/FaqSection';
import TransformExp from './components/TranformExp';
import TiltArrow from './assests/tiltArrow.svg'
import HamburgerMenu from './components/HamburgerMenu';
import BackVideo from './assests/ScreenCloud.mp4'
import img1 from './assests/img1.svg'
import img2 from './assests/img2.svg'
import img3 from './assests/img3.svg'
import img4 from './assests/img4.svg'
import img5 from './assests/img5.svg'
import img6 from './assests/img6.svg'
import img7 from './assests/img7.svg'
import { setupScrollAnimations } from './components/scrollAnimations';
import AutomatedIssueSummarization1 from './assests/Agent SRE/Solution/Automated issue summarization/Assisted and Collaborative Intelligence.jpg'
import AutomatedIssueSummarization2 from './assests/Agent SRE/Solution/Automated issue summarization/Automated Efficiency.jpg'
import AutomatedIssueSummarization3 from './assests/Agent SRE/Solution/Automated issue summarization/Instant Root Cause Identification.jpg'
import AutomatedIssueSummarization4 from './assests/Agent SRE/Solution/Automated issue summarization/Proactive Detection.jpg'
import AutomatedIssueSummarization5 from './assests/Agent SRE/Solution/Automated issue summarization/Real-Time Hybrid Insights.jpg'
import AiDrivenRecommendation1 from './assests/Agent SRE/Solution/ai driven recommendation/Automated Issue Resolution Guidance.jpg'
import AiDrivenRecommendation2 from './assests/Agent SRE/Solution/ai driven recommendation/Dynamic Workload Balancing.jpg'
import AiDrivenRecommendation3 from './assests/Agent SRE/Solution/ai driven recommendation/Predictive Optimization.jpg'
import AiDrivenRecommendation4 from './assests/Agent SRE/Solution/ai driven recommendation/Smart Scaling Suggestions.jpg'
import AiDrivenRecommendation5 from './assests/Agent SRE/Solution/ai driven recommendation/Tailored Infrastructure Recommendations.jpg'
import Automated1 from './assests/Agent SRE/Solution/Automated config management/Automated Code Generation & Fixes.jpg'
import Automated2 from './assests/Agent SRE/Solution/Automated config management/Code-Driven Infrastructure Management.jpg'
import Automated3 from './assests/Agent SRE/Solution/Automated config management/End-to-End Configuration Automation.jpg'
import Automated4 from './assests/Agent SRE/Solution/Automated config management/Integrated IT Operations Management.jpg'
import Automated5 from './assests/Agent SRE/Solution/Automated config management/Seamless Multi-Cloud Configuration.jpg'


function App() {

  useEffect(() => {
    const bookDemoSection = document.querySelector('.book-demo-section');
    const demoSection = document.querySelector('.demo-section');
    const infoBoxRow = document.querySelector('.info-box-row');
    bookDemoSection.style.animation = 'none';
    demoSection.style.animation = 'none';
    infoBoxRow.style.animation = 'none';
    setTimeout(() => {
      bookDemoSection.style.animation = '';
      demoSection.style.animation = '';
      infoBoxRow.style.animation = '';
    }, 100);

    setupScrollAnimations();
  }, []);

  const [selectedButton, setSelectedButton] = React.useState(1);
  const buttons = [
    { id: 1, label: 'Automated Issue Summarization' },
    { id: 2, label: 'AI-Driven Recommendations' },
    { id: 3, label: 'Automated Config Management' },
  ];

  const solutionRef = useRef(null);
  const agentSRERef = useRef(null);
  const useCasesRef = useRef(null);
  const faqRef = useRef(null);
  const headerRef = useRef(null);


  const handleButtonClick = (id) => {
    setSelectedButton(id);
  };

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offset = 100;
      const top = ref.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  return (
    <div className='full-length-container' >
      <div className="header">
        <img className='header-logo' src={headerLogo} onClick={() => scrollToRef(headerRef)} />
        <div className='header-links'>
          <p className='top-header-link-text' onClick={() => scrollToRef(solutionRef)}>How it works</p>
          <p className='top-header-link-text' onClick={() => scrollToRef(agentSRERef)}>Why Agent SRE</p>
          <p className='top-header-link-text' onClick={() => scrollToRef(useCasesRef)}>Real World Examples</p>
          <p className='top-header-link-text'onClick={() => scrollToRef(faqRef)}>FAQ</p>
        </div>
        <HamburgerMenu onMenuItemClick={(ref) => {
          if (ref === 'solution') scrollToRef(solutionRef);
          else if (ref === 'agentSRE') scrollToRef(agentSRERef);
          else if (ref === 'useCases') scrollToRef(useCasesRef);
          else if (ref === 'faq') scrollToRef(faqRef);
        }} />
      </div>
      <div className='book-demo-section'>
        <video className='VideoTag' autoPlay loop muted>
          <source src={BackVideo} type='video/mp4' />
        </video>
        <div className='demo-section'>
          <div className="demo-section-header" ref={headerRef}>AI Agents for Site Reliability Engineering</div>
          <div className='demo-section-header-subtext'>Intelligent Observability and DevOps</div>
          <div className='top-button' onClick={() => window.open('https://demo.akira.ai/agentsre', '_blank')}>
            <>Book Demo</>
            <img className='botton-arrow-tilt' src={TiltArrow} alt="Arrow" />
          </div>
        </div>
      </div>
      <div className='central-container'>
        <div className='info-box-row'>
          <div className='info-box'>
            <h2 >Automation as Code </h2>
            <p className='info-box-lower'>AI Agents automate workflows and manage infrastructure tasks, ensuring accuracy and reducing manual effort.</p>
          </div>
          <div className='info-box info-box-green-shadow'>
            <h2 >Investigation and Remediation </h2>
            <p className='info-box-lower'>AI Agents identify issues in real-time, automate resolutions, and enhance system reliability. </p>
          </div>
        </div>
      </div>
      <div className='central-container scroll-animate'>
        <div className='logo-container'>
          <p className='logo-header'>
            Build Trust and Transparency with Industry Leading Partners
          </p>
          <div className='logo-container-images'>
            <img src={img1} />
            <img src={img2} />
            <img src={img3} />
            <img src={img4} />
            <img src={img5} />
            <img src={img6} />
            <img src={img7} />
          </div>
        </div>
      </div >

      <div className='central-container scroll-animate' ref={solutionRef}>
        <div className='solution-container'>
          <p className='solution-text' >
            Solution
          </p>
          <p className='solution-header'>
            Unlock Power of Agentic AI for DevOps
          </p>
          <p className='solution-description'>
            Leverage AI agents to automate tasks, optimize workflows, and enhance reliability for seamless DevOps operations
          </p>
        </div>
      </div>


      <div className='central-container scroll-animate'>
        <div className='sap-buttons'>
          {buttons.map((button) => (
            <Button
              key={button.id}
              sx={{
                fontFamily: 'Manrope',
                borderRadius: '12px',
                padding: '15px',
                height: '43px',
                background: selectedButton === button.id ? '#A67CFF' : '#A67CFF30',
                width: 'max-content',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 600,
                boxShadow: 'none',
                color: selectedButton === button.id ? 'white' : '#5A31B3',
              }}
              variant="contained"
              onClick={() => handleButtonClick(button.id)}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </div>

      {selectedButton === 1 ? (
        <div className='central-container scroll-animate'>
          <div className='sap-boxes'>
            <div className='sap-box-row-one'>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={AutomatedIssueSummarization1} />
                <div className='sap-box-text'>
                  <h2>Real-Time Hybrid Insights </h2>
                  <p className='sap-box-text-lower'>AI agents detect and summarize issues across hybrid environments instantly.</p>
                </div>
              </div>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={AutomatedIssueSummarization2} />
                <div className='sap-box-text'>
                  <h2 >Assisted and Collaborative Intelligence </h2>
                  <p className='sap-box-text-lower'>AI combines assisted and collaborative intelligence to recommend solutions for complex issues.</p>
                </div>
              </div>

            </div>
            <div className='sap-box-row-two'>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={AutomatedIssueSummarization3} />
                <div className='sap-box-text'>
                  <h2 >Proactive Detection </h2>
                  <p className='sap-box-text-lower'>AI identifies potential issues early, preventing escalations and reducing downtime</p>
                </div>
              </div>
              <div className='sap-unit'>
                <div className='sap-box-text'>
                  <h2>Automated Efficiency </h2>
                  <p className='sap-box-text-lower'>AI automates issue summarization, minimizing manual effort across hybrid environments.</p>
                </div>
                <img style={{ borderRadius: '16px' }} src={AutomatedIssueSummarization4} />
              </div>
              <div className='sap-unit'>
                <img src={AutomatedIssueSummarization5} />
                <div className='sap-box-text'>
                  <h2 >Instant Root Cause Identification </h2>
                  <p className='sap-box-text-lower'>AI analyzes logs and system behavior to identify root causes, speeding up resolution time.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      ) : selectedButton === 2 ? (
        <div className='central-container scroll-animate'>
          <div className='sap-boxes'>
            <div className='sap-box-row-one'>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={AiDrivenRecommendation1} />
                <div className='sap-box-text'>
                  <h2 >Tailored Infrastructure Recommendations </h2>
                  <p className='sap-box-text-lower'>AI analyzes system health to suggest infrastructure improvements and prevent issues.</p>
                </div>
              </div>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={AiDrivenRecommendation2} />
                <div className='sap-box-text'>
                  <h2 >Smart Scaling Suggestions </h2>
                  <p className='sap-box-text-lower'>AI recommends resource scaling based on real-time performance data, optimizing usage.</p>
                </div>
              </div>

            </div>
            <div className='sap-box-row-two'>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={AiDrivenRecommendation3} />
                <div className='sap-box-text'>
                  <h2 >Automated Issue Resolution Guidance</h2>
                  <p className='sap-box-text-lower'>AI provides precise suggestions for quick issue resolution, reducing downtime.</p>
                </div>
              </div>
              <div className='sap-unit'>
                <div className='sap-box-text'>
                  <h2 >Predictive Optimization </h2>
                  <p className='sap-box-text-lower'>AI anticipates issues and recommends proactive actions for peak performance and reliability.</p>
                </div>
                <img style={{ borderRadius: '16px' }} src={AiDrivenRecommendation4} />
              </div>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={AiDrivenRecommendation5} />
                <div className='sap-box-text'>
                  <h2 >Dynamic Workload Balancing </h2>
                  <p className='sap-box-text-lower'>AI recommends adjustments to workloads to ensure optimal performance and resource distribution across systems.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      ) : (
        <div className='central-container scroll-animate'>
          <div className='sap-boxes'>
            <div className='sap-box-row-one'>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={Automated1} />
                <div className='sap-box-text'>
                  <h2 >Code-Driven Infrastructure Management </h2>
                  <p className='sap-box-text-lower'>AI automates Infrastructure as Code, ensuring consistent configurations across environments.</p>
                </div>
              </div>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={Automated2} />
                <div className='sap-box-text'>
                  <h2>Automated Code Generation & Fixes </h2>
                  <p className='sap-box-text-lower'>AI generates and deploys code, resolving issues without manual intervention.</p>
                </div>
              </div>

            </div>
            <div className='sap-box-row-two'>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={Automated3} />
                <div className='sap-box-text'>
                  <h2 >Integrated IT Operations Management </h2>
                  <p className='sap-box-text-lower'>AI manages IT operations, offering fixes and recommendations for tickets, upgrades, and more.</p>
                </div>
              </div>
              <div className='sap-unit'>
                <div className='sap-box-text'>
                  <h2 >End-to-End Configuration Automation </h2>
                  <p className='sap-box-text-lower'>AI automates configuration management, ensuring compliance and reducing operational overhead.</p>
                </div>
                <img style={{ borderRadius: '16px' }} src={Automated4} />
              </div>
              <div className='sap-unit'>
                <img style={{ borderRadius: '16px' }} src={Automated5} />
                <div className='sap-box-text'>
                  <h2 >Seamless Multi-Cloud Configuration </h2>
                  <p className='sap-box-text-lower'>AI ensures uniform configurations across multi-cloud environments, maintaining consistency and performance across different platforms.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      )}

      <div className='central-container scroll-animate' ref={agentSRERef}>
        <div className='solution-container'>
          <p className='solution-text'>
            AgentSRE
          </p>
          <p className='solution-header'>
            Fleet of AI Agents for DevOps
          </p>
          <p className='solution-description'>
            Harness AI agents to automate workflows, resolve issues, and optimize infrastructure for seamless DevOps operations.
          </p>
        </div>
      </div>
      <div className=' scroll-animate'>
        <StairContainer />
      </div>

      < div ref={useCasesRef}>
        <div className=' scroll-animate'>
          <UseCases />
        </div>
      </div>

      <div className='central-container scroll-animate'>
        <div className='solution-container'>
          <p className='solution-text'>
            Features
          </p>
          <p className='solution-header'>
            Boost DevOps Performance with Agentic AI for SRE
          </p>
          <p className='solution-description'>
            Automate key DevOps tasks to enhance efficiency and reliability
          </p>
        </div>
      </div>
      <div className=' scroll-animate'>
        <Features />
      </div>
      <div className='central-container scroll-animate' ref={faqRef}>
        <div className='solution-container'>
          <p className='solution-text'>
            FAQ
          </p>
          <p className='solution-header'>
            Everything You Need to Know About Agent SRE
          </p>
        </div>
      </div>
      <div className=' scroll-animate'>
        <FaqSection />
      </div>
      <div className=' scroll-animate'>
        <TransformExp />
      </div>
      <div className="footer ">
        <div className='central-container-footer'>

          <img className='footer-logo' src={headerLogo} />
          <p className='footer-desc'>Leverage AI-driven insights and automation to streamline operations, reduce downtime, and boost reliability.</p>
          <hr className="footer-divider" />

          <div className='footer-bottom'>
            <div className='legal'>
              <p>Legal</p>
              <p>Privacy Policy</p>
              <p>Terms & Conditions</p>
            </div>
            <p>Copyright 2024. All rights reserved</p>
          </div>
        </div>
      </div>

    </div>

  );
}

export default App;
