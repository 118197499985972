import React from 'react';
import './stairContainer.css'
import agent10 from '../assests/agent10.svg'
import  agent6 from '../assests/agent6.svg'
import agent2 from '../assests/agent2.svg'
import agent4 from '../assests/robo-top.svg'

function StairContainer(){
    return(
        <div className='central-container'>
            <div className='agent-boxes'>

                <div className='agent-column'>
                <div className='agent-unit'>
                <div className='agent-box-text'>
                  <p className='agent-box-text-header'>AI Agent for Operations </p>
                  <p className='agent-box-text-lower'>Automates operations tasks, monitors systems, and resolves issues in real time</p>
                </div>
                <img style={{    width: '289px',height: '210px', display: 'flex', alignSelf: 'center'}} src={agent4}/>
                </div>
                <div className='agent-unit-green'>
                <div className='agent-box-text'>
                  <p className='agent-box-text-header'>AI Agent for Incident Management </p>
                  <p className='agent-box-text-lower'>Automates incident detection, provides real-time analysis, and resolves issues swiftly to minimize downtime and disruptions. </p>
                </div>
                </div>
                </div>

                <div className='agent-column'>
                <div className='agent-unit'>
             
                <div className='agent-box-text'>
                  <p className='agent-box-text-header'>AI Teammates for Database DevOps</p>
                  <p className='agent-box-text-lower'>Manages database performance, automates queries, and ensures high availability</p>
                </div>
                <img style={{    width: '202px',height: '152px', display: 'flex', alignSelf: 'self-end'}} src={agent10}/>
                </div>
                <div className='agent-unit'>
              
                <div className='agent-box-text'>
                  <p className='agent-box-text-header'>AI Teammates for Code Reviews </p>
                  <p className='agent-box-text-lower'>Automates code reviews, detects bugs, and ensures coding standard compliance. </p>
                </div>
                <img style={{width: '245px',height: '190px', display: 'flex', alignSelf: 'center'}} src={agent2}/>
                </div>
                </div>

                  <div className='agent-column'>
                <div className='agent-unit-purple'>
                  <img src={""}/>
                <div className='agent-box-text'>
                  <p className='agent-box-text-header-purple'> AI Teammates for Infrastructure Management</p>
                  <p className='agent-box-text-lower-purple'>  Manages infrastructure as code, automates deployments, and ensures optimal system performance through real-time monitoring and adjustments. </p>
                </div>
                </div>
                <div className='agent-unit'>
                <div className='agent-box-text'>
                  <p className='agent-box-text-header'>AI Teammates for DevOps</p>
                  <p className='agent-box-text-lower'>Optimizes DevOps workflows, automates deployments, and enhances system reliability</p>
                </div>
                <img style={{ width:'289px', height:'253.34px', display: 'flex', alignSelf: 'center'}} src={agent6}/>
                </div>
                </div>
                
                
               
        
            </div>
            </div>

        );
}
export default StairContainer;
