import React, { useState } from 'react';
import './HamburgerMenu.css'; // Create this CSS file for styling

const HamburgerMenu = ({ onMenuItemClick }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`hamburger-menu ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <div className="McButton" data="hamburger-menu">
                <b></b>
                <b></b>
                <b></b>
            </div>
            {isOpen && (
                <div className="menu-items">
                    <p onClick={() => onMenuItemClick('solution')}>How it works</p>
                    <p onClick={() => onMenuItemClick('agentSRE')}>Why Agent SRE</p>
                    <p onClick={() => onMenuItemClick('useCases')}>Real World Examples</p>
                    <p onClick={() => onMenuItemClick('faq')}>FAQ</p>
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;