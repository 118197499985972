import React from 'react';
import './TransformExp.css'
import BottomBot from '../assests/robo-bottom.svg'
import TiltArrow from '../assests/tiltArrow.svg'

function TransformExp(props) {


    return (
        <div className='central-container'>
            <div className='transform-exp'>
                <div className='transform-desc'>
                    <p className='desc-header'>Ready to Elevate Your DevOps with Agentic AI?</p>
                    <p className='desc-desc'>Discover how AI-driven automation can transform your DevOps operations and boost system efficiency.</p>
                    <div className='bottom-button' onClick={() => window.open('https://demo.akira.ai/agentsre', '_blank')}>
                        <>Book Your Demo Now</>
                        <img className="bottom-tilt-arrow" src={TiltArrow} />
                    </div>
                </div>
                <div className='bottom-bot'>
                    <img src={BottomBot} />
                </div>
            </div>
        </div>
    );
}


export default TransformExp;


