import React, { useState } from 'react';
import './Features.css';
import Globe from '../assests/globe.svg'
import DriverRefresh from '../assests/driver-refresh.svg'
import Keyboard from '../assests/keyboard-open.svg'
import Cloud from '../assests/cloud-connection.svg'
import RightArrow from '../assests/arrow-right.svg'
import ContainerImage from '../assests/container-image.svg'
import feature1 from '../assests/Agent SRE/Features/Automatic Failure Detection with Suggested Remedies.jpg'
import feature2 from '../assests/Agent SRE/Features/On-Demand CodeConfig Generation and Deployment.jpg'
import feature3 from '../assests/Agent SRE/Features/Prompt-Driven On-Demand Workflow Management.jpg'
import feature4 from '../assests/Agent SRE/Features/Proactive Configuration Management.jpg'

const Features = () => {
    const [selectedMenu, setSelectedMenu] = useState('Menu 1');

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu);
    };

    const renderContent = () => {
        switch (selectedMenu) {
            case 'Menu 1':
                return <div className='menu-item-content-container'>
                    <p className='content-header'>Automatic Failure Detection with Suggested Remedies </p>
                    <p className='content-desc'>AI agents detect failures in real-time and provide actionable remedies, ensuring rapid recovery and minimal system disruption.</p>
                    <div className='content-image-container'>
                        <img style={ {display: 'flex',width: '100%', borderRadius: '16px'}} src={feature1} />
                    </div>

                </div>;
            case 'Menu 2':
                return <div className='menu-item-content-container'>
                <p className='content-header'>On-Demand Code/Config Generation and Deployment</p>
                <p className='content-desc'>Instantly generate and deploy code or configurations as needed, streamlining updates and ensuring continuous deployment without manual intervention. </p>
                <div className='content-image-container'>
                    <img style={ {display: 'flex',width: '100%', borderRadius: '16px'}} src={feature2} />
                </div>

            </div>;
            case 'Menu 3':
                return <div className='menu-item-content-container'>
                <p className='content-header'>Prompt-Driven On-Demand Workflow Management </p>
                <p className='content-desc'>Leverage AI-driven prompts to manage workflows on demand, enabling faster execution of tasks and improved operational efficiency. </p>
                <div className='content-image-container'>
                    <img style={ {display: 'flex',width: '100%', borderRadius: '16px'}} src={feature3} />
                </div>

            </div>;
            case 'Menu 4':
                return <div className='menu-item-content-container'>
                    <p className='content-header'>Proactive Configuration Management </p>
                    <p className='content-desc'>AI agents manage configurations, applying updates and ensuring compliance across environments without the need for manual input. </p>
                    <div className='content-image-container'>
                        <img style={ {display: 'flex',width: '100%',borderRadius: '16px'}} src={feature4} />
                    </div>

                </div>;
            default:
                return null;
        }
    };

    return (
        <div className='central-container'>
            <div className="outer-container">
                <div className="menu-column">
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {['Menu 1', 'Menu 2', 'Menu 3', 'Menu 4'].map((menu) => (
                            <li key={menu} onClick={() => handleMenuClick(menu)} className={`menu-item ${selectedMenu === menu ? 'selected' : ''}`}>
                                {menu === 'Menu 1' ?
                                    <div className='menu-item-text-container'>
                                        <div className='top-header'>
                                            <div className='img-header'>
                                                <img className='header-image' src={Globe} />
                                                <p className='menu-item-header'>Automatic Failure Detection with Suggested Remedies </p>
                                            </div>
                                            <img src={RightArrow} />
                                        </div>
                                        <p className='menu-item-lower'>AI agents detect failures in real-time and provide actionable remedies, ensuring rapid recovery and minimal system disruption.</p>

                                    </div>
                                    : menu === 'Menu 2' ?
                                        <div className='menu-item-text-container'>
                                            <div className='top-header'>
                                                <div className='img-header'>
                                                    <img className='header-image' src={Keyboard} />
                                                    <p className='menu-item-header'>On-Demand Code/Config Generation and Deployment </p>
                                                </div>
                                                <img src={RightArrow} />
                                            </div>
                                            <p className='menu-item-lower'>Instantly generate and deploy code or configurations as needed, streamlining updates and ensuring continuous deployment without manual intervention. </p>

                                        </div>

                                        : menu === 'Menu 3' ?
                                            <div className='menu-item-text-container'>
                                                <div className='top-header'>
                                                    <div className='img-header'>
                                                        <img className='header-image' src={DriverRefresh} />
                                                        <p className='menu-item-header'>Prompt-Driven On-Demand Workflow Management</p>
                                                    </div>
                                                    <img src={RightArrow} />
                                                </div>
                                                <p className='menu-item-lower'>Leverage AI-driven prompts to manage workflows on demand, enabling faster execution of tasks and improved operational efficiency. </p>

                                            </div>
                                            : menu === 'Menu 4' ?
                                                <div className='menu-item-text-container'>
                                                    <div className='top-header'>
                                                        <div className='img-header'>
                                                            <img className='header-image' src={Cloud} />
                                                            <p className='menu-item-header'>Proactive Configuration Management </p>
                                                        </div>
                                                        <img src={RightArrow} />
                                                    </div>
                                                    <p className='menu-item-lower'>AI agents manage configurations, applying updates and ensuring compliance across environments without the need for manual input.</p>

                                                </div>
                                                : menu}

                            </li>
                        ))}
                    </ul>
                </div>
                <div className="content-column" style={{ padding: '20px', flex: 1 }}>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default Features;
